import React from 'react'
import { graphql } from 'gatsby'
import Previewable from '../components/Previewable';
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { SuppliersNavigation } from '../components/Products/SuppliersNavigation';
import { TextHeader } from '../acf/TextHeader/TextHeader.jsx';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import { decodeEntities } from '../utils/htmlParse';
import './suppliers.scss';

const ProductsSupplier = (props) => {
  const {
    data,
    location,
    pageContext
  } = props;
  // const { id } = pageContext;
  const {
    wordpressPost,
    suppliers
  } = data;
  if (!wordpressPost) return null;
  const {
    supplierUrl,
    supplierUrlTitle,
    features,
    supplierTitle,
    logo
  } = wordpressPost.acf;
  return (
    <Layout location={location} pageTitle={supplierTitle} className="suppliers-page-template">
      <SEO
        title={supplierTitle}
        desc={features}
      />
      
      <SuppliersNavigation suppliers={suppliers} />
      
      <TextHeader
        smallHeading="Supplier"
        heading={`${supplierTitle}`}
      />
      
      {(features || supplierUrl) && (
        <div className="supplier-content">
          <div className="wrapper">
            {logo && <p><img src={logo.source_url} class="logo" /></p>}
            {features && (
              <div 
                className="features" 
                dangerouslySetInnerHTML={{__html: features}} 
              />
            )}
            {supplierUrl && (
                <a href={supplierUrl} target="_blank" className="button" rel="noopener noreferrer">
                  {decodeEntities(supplierUrlTitle || 'Visit Supplier Website')}
                </a>
            )}
          </div>
        </div>
      )}

      <FormBlock
        heading="Can't find what you're looking for? Contact us today."
        smallHeading="Contact"
        form="general"
        location={location}
      />

    </Layout>
  );
}

export default Previewable(ProductsSupplier, 'product_supplier')

export const pageQuery = graphql`
  query SuppliersFilterPageQuery($id: String!) {
    suppliers: allWordpressWpProductSupplier {
      nodes {
        id
        industries
        slug
        link
        acf {
          logo {
            source_url
          }
          supplierCode
          supplierTitle
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProductSupplier(id: { eq: $id }) {
      slug
      wordpress_id
      acf {
        supplierTitle
        supplierCode
        features
        logo {
          source_url
        }
        supplierUrl
        supplierUrlTitle
        wordpress_id
      }
      slug
    }
  }
`
