import React, { useState, useEffect } from 'react';
import GatsbyLink from '../GatsbyLink';
import { getScrollPos, isClient } from '../../utils/helpers';
import './ProductsNavigation.scss'

const NavItem = ({supplier}) => {
  return (
    <li>
      <GatsbyLink to={supplier.link}>{`${supplier.acf.supplierTitle}`}</GatsbyLink>
    </li>
  )
}

export const SuppliersNavigation = ({ suppliers }) => {
  const [productsNavigationOpen, setProductsNavigationOpen] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const toggleProductsNavigation = () => {
    setProductsNavigationOpen(!productsNavigationOpen);
  }
  const onScroll = () => {
    setScrollPos(getScrollPos);
  }
  useEffect(() => {
    if (isClient) window.addEventListener('scroll', onScroll);
    return () => {
      if (isClient) window.removeEventListener('scroll', onScroll);
    };
  })
  return (
    <div className={`products-navigation-container ${productsNavigationOpen ? 'open ' : ''} ${scrollPos > 200 ? 'fixed' : ''}`}>
      <button className="products-navigation-toggle" type="button" onClick={toggleProductsNavigation} />
      <div className="products-navigation">
        <ul>
          {suppliers && suppliers.nodes && suppliers.nodes.map((supplier, index) => (
            <NavItem
              key={index}
              index={index}
              supplier={supplier}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
